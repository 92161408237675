var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"ui very basic table",attrs:{"aria-describedby":"Table des données du signalement"}},[_c('tbody',[(_vm.featureType)?_c('tr',[_vm._m(0),_c('td',[_c('FeatureTypeLink',{attrs:{"feature-type":_vm.featureType}})],1)]):_vm._e(),_vm._l((_vm.featureFields),function(field){return _c('tr',{key:field.name},[(!field.isDeactivated)?[_c('td',[_c('strong',{class:{ required: field.is_mandatory }},[_vm._v(" "+_vm._s(field.label)+" ")])]),_c('td',[_c('strong',{staticClass:"ui form"},[(_vm.fastEditionMode && _vm.canEditFeature && _vm.extra_forms.length > 0)?_c('span',{attrs:{"id":field.label}},[_c('ExtraForm',{ref:"extraForm",refInFor:true,attrs:{"field":field}})],1):(field.field_type === 'boolean')?_c('i',{class:[
                  'icon',
                  field.value ? 'olive check' : 'grey times',
                ],attrs:{"aria-hidden":"true"}}):(field.value && field.field_type === 'multi_choices_list')?_c('span',[_vm._v(" "+_vm._s(field.value.join(', '))+" ")]):_c('span',[_vm._v(" "+_vm._s(field.value && field.value.label ? field.value.label : field.value)+" ")])])])]:_vm._e()],2)}),_c('tr',[_c('td',[_vm._v(" Auteur ")]),(_vm.currentFeature.properties)?_c('td',[_vm._v(" "+_vm._s(_vm.currentFeature.properties.display_creator)+" ")]):_vm._e()]),_c('tr',[_c('td',[_vm._v(" Statut ")]),_c('td',[(_vm.currentFeature.properties && _vm.currentFeature.properties.status)?_c('i',{class:['icon', _vm.statusIcon],attrs:{"aria-hidden":"true"}}):_vm._e(),(_vm.fastEditionMode && _vm.canEditFeature && _vm.form)?_c('FeatureEditStatusField',{staticClass:"inline",attrs:{"status":_vm.form.status.value.value || _vm.form.status.value}}):_c('span',[_vm._v(" "+_vm._s(_vm.statusLabel)+" ")])],1)]),_c('tr',[_c('td',[_vm._v(" Date de création ")]),(_vm.currentFeature.properties && _vm.currentFeature.properties.created_on)?_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.currentFeature.properties.created_on))+" ")]):_vm._e()]),_c('tr',[_c('td',[_vm._v(" Date de dernière modification ")]),(_vm.currentFeature.properties && _vm.currentFeature.properties.updated_on)?_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.currentFeature.properties.updated_on))+" ")]):_vm._e()])],2)]),_c('h3',[_vm._v("Liaison entre signalements")]),_c('table',{staticClass:"ui very basic table",attrs:{"aria-describedby":"Table des signalements lié à ce signalement"}},[_c('tbody',[_vm._l((_vm.linked_features),function(link,index){return _c('tr',{key:link.feature_to.title + index},[(link.feature_to.feature_type_slug)?_c('th',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(link.relation_type_display)+" ")]):_vm._e(),(link.feature_to.feature_type_slug)?_c('td',[_c('FeatureFetchOffsetRoute',{attrs:{"feature-id":link.feature_to.feature_id,"properties":{
              title: link.feature_to.title,
              feature_type: { slug: link.feature_to.feature_type_slug }
            }}}),_vm._v(" ("+_vm._s(link.feature_to.display_creator)+" - "+_vm._s(link.feature_to.created_on)+") ")],1):_vm._e()])}),(_vm.linked_features.length === 0)?_c('tr',[_vm._m(1)]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v(" Type de signalement ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('em',[_vm._v(" Aucune liaison associée au signalement. ")])])
}]

export { render, staticRenderFns }